<template>
  <div class="app-container">
    <!-- 标题 -->
    <div class="title_leftm">
      <div style="float: left;">
        <span class="span_title">{{ $t('m.navbar.notice_title') }}</span>
        <span v-if="this.$language === 'cn'" class="span_sub_title"> / Conference Information</span>
      </div>
    </div>
    <!-- -->
    <div>
      <table class="comm_visits lineHeight">
        <tbody>
        <tr v-for="item in list" :key="item.id">
          <td>
            <ol>
              <li>
                <div class="title_box">
                  <a @click="handleGoDetails(item)">
                    {{ item.title }}
                  </a>
                </div>
              </li>
            </ol>
          </td>
          <td class="time1">
            【{{ item.time }}】
          </td>
        </tr>
        </tbody>
      </table>
      <div style="margin-top: 30px; background-color: #dddbdb; height: 1px;" />
      <div class="pagination-container" style="margin-top: 30px;">
        <el-pagination
          :page-size="listQuery.pageSize"
          :current-page.sync="listQuery.pageNum"
          :total="total"
          background
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
//
import { noticeList } from '@/api/cceec/news'

const defaultListQuery = {
  pageNum: 1,
  pageSize: 20
}
export default {
  name: 'Dynamic',
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      loading: false,
      list: [],
      total: 0
    }
  },
  created() {
    this.listQuery = Object.assign({
      languageType: this.$languageType
    }, this.listQuery)
    this.getList()
  },
  methods: {
    //  列表
    getList() {
      this.loading = true
      noticeList(this.listQuery).then(res => {
        this.loading = false
        this.list = res.data.list
        this.total = res.data.total
      }).catch(() => {
        this.loading = false
      })
    },
    //  动态详情
    handleGoDetails(item) {
      if (item.link) {
        // 外链
        const dom = document.createElement('a') // 创建a标签
        dom.setAttribute('target', '_blank')  // href链接
        dom.setAttribute('href', item.link)  // href链接
        dom.click() // 自执行点击事件
      } else {
        // 非外链
        this.$router.push({ path: '/dynamic/noticeDetails', query: { id: item.id } })
      }
    },
    //  页码改变
    handleCurrentChange(val) {
      this.listQuery.pageNum = val
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
  .title_leftm {
    margin: 20px auto 0;
    width: 1200px;
    height: 40px;
    white-space: nowrap;
    border-bottom: 1px #dddbdb dashed;
    .span_title {
      font-size: 18px;
      color: #2890eb;
    }
    .span_sub_title {
      color: #999;
      font-size: 14px;
      font-family: arial;
    }
  }
  .comm_visits {
    width: 1200px;
    margin-top: 25px;
  }
  .lineHeight {
    tr {
      height: 32px;
      td {
        padding: 0;
      }
    }
  }
  ol {
    margin: 0 0 0 -25px;
    padding-top: 10px;
    list-style: disc;
    /*margin-bottom: 0px !important;*/
    a {
      color: #656565;
    }
  }
  table {
    font-size: 14px;
    border-spacing: 0;
    border-collapse: collapse;
    background-color: transparent;
  }
  .time1 {
    float: right;
    margin-top: 12px;
  }
  .title_box {
    margin-left: 5px;
    font-size: 14px;
    width: 700px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
</style>
